import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"
import EncloseFloats from "../components/enclose-floats"
import { EntryHeader, EntryTitle } from "../components/entry"
import header from "../utils/header"

const BlankLink = props => (
  <a rel="noopener noreferrer" target="_blank" {...props} />
)

const H2 = header(2)

const NotFoundPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Card variant="primary">
      <EncloseFloats>
        <EntryHeader>
          <EntryTitle>Privacy</EntryTitle>
        </EntryHeader>
        <p>
          <b>Vancouver Anime</b> is a relatively simple site run by Daniel
          Friesen under the sole proprietorship named <em>VAnime Soft</em>.
          <br />
          But even the plainest of websites often use cookies and 3rd party
          services that affect your privacy.
          <br />
          {"Here's an overview of what we use."}
        </p>

        <H2>Traffic Logs</H2>
        <p>
          The service we use to host this website may keep traffic logs for a
          period of time. These traffic logs may contain information your
          browser automatically sends to every website you visit, such as: your
          IP address, the URLs of pages you visit, the URLs of pages that
          referred you to a page on our site, and the User Agent of the browser
          you are using.
        </p>
        <p>
          These logs are separate from the 3rd party services we use and will
          not be combined data from other sources.
        </p>

        <H2>Google Analytics</H2>
        <p>
          We use Google Analytics (GA) to gather useful statistics like how many
          people visit the site, what pages are most popular, what countries are
          our visitors from, and what browsers and screen sizes we should
          support.
        </p>
        <p>
          To improve the privacy of these analytics we do not use {"GA's"}{" "}
          advertising services and we anonymize the IP addresses sent to GA.
        </p>
        <p>
          You can find more information on {"Google's"}{" "}
          <BlankLink href="https://policies.google.com/technologies/partner-sites">
            Privacy Policy for Parter Sites
          </BlankLink>
        </p>

        <H2>Google Fonts</H2>
        <p>
          We use{" "}
          <BlankLink href="https://fonts.google.com/">Google Fonts</BlankLink>{" "}
          to provide some of the fonts that determine what the text on this site
          looks like.
        </p>
        <p>
          <BlankLink href="https://developers.google.com/fonts/faq#what_does_using_the_google_fonts_api_mean_for_the_privacy_of_my_users">
            More info on Google Fonts and privacy
          </BlankLink>
          .
        </p>

        <H2>reCAPTCHA</H2>
        <p>
          We use the{" "}
          <BlankLink href="https://developers.google.com/recaptcha/">
            reCAPTCHA
          </BlankLink>{" "}
          service to deter bots from spamming our website feedback form.
        </p>
        <p>
          When you visit the Website Feedback page, reCAPTCHA may collect data
          and send it to Google for analysis. The information collected by
          reCAPTCHA will be used for improving reCAPTCHA and for general
          security purposes. It is not supposed to be used for personalized
          advertising by Google.
        </p>
        <p>
          You may also wish to read{" "}
          <BlankLink href="https://policies.google.com/privacy">
            {"Google's Privacy Policy"}
          </BlankLink>
          .
        </p>

        <H2>Zendesk</H2>
        <p>We use Zendesk support tickets to run our Website Feedback form.</p>
        <p>
          If you send website feedback to us, Zendesk will receive the
          information you submit in our feedback form as well as information
          your browser sends such as your IP address and what browser you are
          using.
        </p>

        <H2>Facebook</H2>
        <p>
          We use the Facebook SDK to include a page widget with the latest info
          from our Facebook page in our sidebar.
        </p>
        <p>
          Facebook may use cookies, web beacons, and other storage technologies
          to collect or receive information through this use of their SDK and
          widget as as described in their{" "}
          <BlankLink href="https://www.facebook.com/about/privacy/">
            Data Policy
          </BlankLink>
          .
        </p>
        <p>
          If you wish to avoid {"Facebook's"} tracking on our site, you may wish
          to turn on the{" "}
          <BlankLink href="https://allaboutdnt.com/">
            {'"Do Not Track"'}
          </BlankLink>{" "}
          feature in your browser. If {'"Do Not Track"'} is enabled we disable
          the Facebook page widget unless you click a link asking to enable the
          feature.
        </p>
      </EncloseFloats>
    </Card>
  </Layout>
)

export default NotFoundPage
